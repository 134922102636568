import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { OrderedMap } from 'immutable';
import loadable from '@loadable/component';

import Loading from 'containers/common/loading';

import VenueModel from 'models/venue';
import HubModel from 'models/hub';
import * as RoutingStyle from 'lib/routing-style';
import { HUB_TYPE } from 'models/search';

import cleanupSearchState from 'action-creators/search/cleanup-search-state';
import cleanupHubState from 'action-creators/search/cleanup-hub-state';
import cleanupVenueState from 'action-creators/search/cleanup-venue-state';

const Search = loadable(() => import('containers/search'), {
  fallback: Loading,
});

const Venue = loadable(() => import('containers/venue'), {
  fallback: Loading,
});

const Hub = loadable(() => import('containers/hub'), {
  fallback: Loading,
});

/*
 * TODO: This is a temporary solution to a bigger problem with our urls. They aren't consistent,
 * so we need a wrapper. React-router doesn't natively support changing apps when queries change,
 * so that is why this wrapper exists.
 */
const propTypes = {
  events: PropTypes.instanceOf(OrderedMap).isRequired,
  destinationType: PropTypes.string.isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string,
    search: PropTypes.string,
  }).isRequired,
  routingStyle: PropTypes.string,
  cleanupSearchState: PropTypes.func.isRequired,
  cleanupHubState: PropTypes.func.isRequired,
  cleanupVenueState: PropTypes.func.isRequired,
  searchText: PropTypes.string,
};

const defaultProps = {
  routingStyle: RoutingStyle.PARKWHIZ,
  searchText: '',
};

class SearchWrapper extends Component {
  componentWillUnmount() {
    this.props.cleanupSearchState();
    this.props.cleanupHubState();
    this.props.cleanupVenueState();
  }

  render() {
    const { location, events, routingStyle, destinationType } = this.props;
    const url = `${location.pathname}${location.search}`;

    if (VenueModel.validURL(url, { routingStyle }) && events.size > 0) {
      return <Venue />;
    }

    if (HubModel.validURL(url) && destinationType === HUB_TYPE) {
      return <Hub />;
    }

    return <Search routerLocation={location} />;
  }
}

const mapDispatchToProps = dispatch => (
  bindActionCreators({
    cleanupSearchState,
    cleanupHubState,
    cleanupVenueState,
  }, dispatch)
);

const mapStateToProps = (state) => {
  const { events, currentSearch, searchText } = state.search;

  return {
    events,
    destinationType: currentSearch.destinationType,
    searchText,
  };
};

SearchWrapper.propTypes = propTypes;
SearchWrapper.defaultProps = defaultProps;

export default connect(mapStateToProps, mapDispatchToProps)(SearchWrapper);
